import { CToast, CToastBody, CToastClose } from "@coreui/react";
import { createContext, useContext, useState } from "react";

const ToastContext = createContext();

export const useToastContext = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toastConfig, setToastConfig] = useState(null);

  const showToast = (message, color = "primary", options = {}) => {
    setToastConfig({
      message,
      color,
      options,
    });

    setTimeout(() => {
      setToastConfig(null);
    }, options.autohide || 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toastConfig && (
        <CToast
          autohide={false}
          visible={true}
          color={toastConfig.color}
          className="text-white"
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            margin: "15px",
            zIndex: 1100,
          }}
        >
          <div className="d-flex">
            <CToastBody>{toastConfig.message}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>
      )}
    </ToastContext.Provider>
  );
};
