import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CModal } from "@coreui/react";
import { Link as ScrollLink } from "react-scroll";

const Topbar = () => {
  const navigate = useNavigate();
  const [demoModalVisible, setDemoModalVisible] = useState(false);
  
  return (
    <>
      <header>
        <nav className="navbar">
          <img
            src="images/salonboost-horizontal-logo (150 x 40 px).svg"
            alt="SalonBoost Logo"
            className="logo"
          />
          <ul className="nav-menu">
            <li>
              <Link to="/vdp-salonboost">
                VDP
              </Link>
            </li>
            <li>
              <ScrollLink to="features">Features</ScrollLink>
            </li>
            <li>
              <ScrollLink to="pricing">Pricing</ScrollLink>
            </li>
            <li>
              <ScrollLink to="testimonial">Why SalonBoost</ScrollLink>
            </li>
            <li>
              <Link onClick={() => setDemoModalVisible(true)} className="btn">
                Book Free Demo
              </Link>
            </li>
            <li>
              <Link to="/register" className="btn">
                Start Free Trial
              </Link>
            </li>
            <li>
              <Link to="/login" className="btn">
                Login
              </Link>
            </li>
          </ul>
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Boost Your Salon Sales with SalonBoost!</h1>
          <p>
            Simplify your salon management, automate bookings, and grow your client base
            effortlessly.
          </p>
          <div className="cta-buttons">
            <button className="cta-button" onClick={() => setDemoModalVisible(true)}>
              Book a Free Demo
            </button>
            <button
              className="cta-button"
              id="freeTrialButton"
              onClick={() => navigate("/register")}
            >
              Start Free Trial
            </button>
          </div>
          <div className="cta-buttons">
            <button className="cta-button" id="loginButton" onClick={() => navigate("/login")}>
              Login
            </button>
          </div>
        </div>
      </section>

      <CModal
        className="demo-modal"
        visible={demoModalVisible}
        onClose={() => {
          setDemoModalVisible(false);
        }}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <div className="demo-modal-content">
          <span className="close" onClick={() => setDemoModalVisible(false)}>
            &times;
          </span>
          <h2>Book a Free Demo</h2>
          <form id="demoForm">
            <label for="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label for="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label for="phone">Phone:</label>
            <input type="tel" id="phone" name="phone" required />

            <button type="submit">Submit</button>
          </form>
        </div>
      </CModal>
    </>
  );
};

export default Topbar;
