import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./landing.css";

const VDPPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://hackerone.com/996303ae-1d2c-477d-8ecd-ce38de266d71/embedded_submissions/script";
    script.async = true;
    script.setAttribute(
      "data-url",
      "https://hackerone.com/996303ae-1d2c-477d-8ecd-ce38de266d71/embedded_submissions/new"
    );
    script.setAttribute("data-name", "h1-embedded-submission");
    script.type = "text/javascript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="description" content="SalonBoost Vulnerability Disclosure Program (VDP)"></meta>
        <title>Vulnerability Disclosure Program | SalonBoost</title>
      </Helmet>
      <style>
        {`
            body {
              font-family: 'Arial', sans-serif;
              background-color: #f8f8ff;
              color: #333;
              margin: 0;
              padding: 0;
            }

            .container {
              max-width: 1200px;
              margin: 0 auto;
              padding: 20px;
            }

            h1 {
              color: #4B0082;
              font-size: 36px;
              text-align: center;
              margin-bottom: 20px;
            }

            p {
              font-size: 18px;
              line-height: 1.6;
              color: #555;
            }

            .content-section {
              background-color: white;
              border-radius: 10px;
              padding: 40px;
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
            }

            .hackerone-section {
              text-align: center;
              margin-top: 30px;
            }

            a {
              color: #4B0082;
              text-decoration: none;
            }

            a:hover {
              text-decoration: underline;
            }
          `}
      </style>
      <div className="container">
        <h1>Vulnerability Disclosure Program</h1>

        <div className="content-section">
          <p>
            At SalonBoost, we value the contributions of the security research community and
            recognize the importance of a coordinated approach to vulnerability disclosure. If you
            have discovered a security vulnerability, we encourage you to let us know immediately.
            We welcome the opportunity to work with you to resolve the issue promptly.
          </p>

          <p>
            Adhering to industry standards is important to us, and our program is covered by
            Coordinated Vulnerability Disclosure, Safe Harbor, Open Scope and Core Ineligible
            Findings, and Detailed Platform Standards.
          </p>
        </div>

        <div className="hackerone-section"></div>
      </div>
    </>
  );
};

export default VDPPage;
