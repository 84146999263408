import React from 'react'

const Pricing = () => {
  return (
    <>
      <section id="pricing" className="pricing">
        <h2>Affordable Pricing Plans</h2>
        <div className="pricing-tiles">
          <div className="pricing-plan">
            <h3>Standard</h3>
            <p>₹1499/month</p>
            <p>₹14388/year (20% off)</p>
            <ul>
              <li>Appointment Scheduling</li>
              <li>Whatsapp Reminder</li>
              <li>Whatsapp Bill Dispatch</li>
              <li>Point of Sale</li>
              <li>Prepaid</li>
              <li>Packages</li>
              <li>Client Management</li>
              <li>Staff Management</li>
              <li>Incentive Management</li>
              <li>Basic Analytics</li>
              <li>Expense Management</li>
              <li>Data Security</li>
              <li>Increase Google 5-Star Ratings and Reviews</li>
              <li>Control Negative Feedback</li>
              <li>Separate Client Login Access</li>
              <li>Incentive Management</li>
              <li>Cash Registry & Petty Cash Management</li>
              <li>Two-Factor Authentication (OTP) - Prepaid Redemption</li>
              <li>Centralized Reports</li>
              <li>Pay As You Go</li>
              <li>Direct WhatsApp Integration</li>
              <li>Lead Inquiry Management</li>
            </ul>
            <a href="https://salonboost.online/register" className="cta-button">
              Start Free Trial
            </a>
          </div>
          <div className="pricing-plan premium">
            <h3>Premium</h3>
            <p>₹2500/month</p>
            <p>₹24000/year (20% off)</p>
            <ul>
              <li>Includes all Standard features</li>
              <li>Automated Lead Management</li>
              <li>Marketing Campaigns</li>
              <li>Consent Form</li>
              <li>Multi-Location Management</li>
              <li>Advanced Analytics</li>
              <li>Internal Product Consumption & Product Transfer</li>
              <li>Automated Feedback</li>
              <li>Business Trend Metrics</li>
              <li>Automated Service Reminders</li>
              <li>Prepaid Expiry Reminders</li>
              <li>Two-Factor Authentication - Restrict New Device Logins</li>
              <li>Immediate Alerts - Invoice Manipulation & Data Downloads</li>
              <li>Masking Customer Contacts</li>
              <li>Dedicated Success Manager</li>
              <li>Resource Management</li>
              <li>Staff Login & Access Control</li>
              <li>
                Loyalty Programs - Packages, Prepaids, Gift Vouchers (Inhouse & Online), Membership
                & Reward Points
              </li>
              <li>Marketing Campaigns - Discount Coupons, Promotional Offers & Greetings</li>
              <li>Third-Party Integrations - Payment Gateways & SMS Vendors</li>
            </ul>
            <a href="https://salonboost.online/register" className="cta-button">
              Start Free Trial
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing