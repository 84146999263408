import React, { useState } from "react";
import { CRow, CCol } from "@coreui/react";

const Faqs = () => {
  const [leftIndex, setLeftIndex] = useState(null);
  const [rightIndex, setRightIndex] = useState(null);

  const toggleLeftFAQ = (index) => {
    setLeftIndex(leftIndex === index ? null : index);
  };

  const toggleRightFAQ = (index) => {
    setRightIndex(rightIndex === index ? null : index);
  };

  const generalFaqData = [
    {
      question: "Do we have to pay extra for Whatsapp?",
      answer: `No, there’s no extra charges for whatsapp messages. We use Whatsapp Web. We understand how important it is to stay connected with your clients for sending invoices, appointment reminders, and promotional messages. You can purchase Whatsapp credits for premium package when you want to automate the whatsapp messages.`,
    },
    {
      question: "The price of salonboost is very competative, will you increase the price?",
      answer: `We will not increase the price for our existing clients, the price will remain same for whatever price you have purchased for 3 years.`,
    },
    {
      question: "Will be there any additional charges if I have more than 10 employees?",
      answer: "No, Our plans are for unlimited employees and unlimited clients.",
    },
    {
      question: "Can I switch my subscription plan?",
      answer: `Absolutely! You can upgrade or downgrade your subscription anytime. Just contact our support team, and we’ll help you with the process.`,
    },
    // {
    //   question: "How secure is my information?",
    //   answer: `Your data security is our top priority. You can select your preferred data center from the beginning. Our servers are located in state-of-the-art data centers with biometric security and around-the-clock monitoring. We also keep our application updated with the latest security features, including SSL encryption at no extra charge.`,
    // },
    {
      question: "Are there fees if I cancel my subscription?",
      answer: `No, there are no cancellation fees with our pay-as-you-go model. You can cancel whenever you like, and your cancellation will take effect at the end of your current term.`,
    },
    {
      question: "What does Premium Support include?",
      answer: `Premium Support is available for our Premium package, offering priority assistance with a response time of just 10 minutes during business hours. We focus on providing enhanced support to clients who choose this option.`,
    },
  ];

  const securityFaqData = [
    {
      question: "How do you protect my salon's data?",
      answer: `We take data protection seriously. All your salon's information is securely encrypted, ensuring that only authorized users can access it.`,
    },
    {
      question: "Will my data be shared with anyone?",
      answer: `No. We respect your privacy and never share your data with any third-party companies. Your data stays secure within the SalonBoost platform.`,
    },
    {
      question: "What if I lose my data?",
      answer: `No worries! We automatically back up your data regularly. This ensures that you won’t lose any important information, even in case of any issues.`,
    },
    {
      question: "Who can access my salon's data?",
      answer: `Only you and the team members you authorize can access your data. You can control who sees what through secure login credentials.`,
    },
    {
      question: "Can I trust SalonBoost with my clients' information?",
      answer: `Yes, absolutely! We follow strict data protection policies and ensure that your client information is handled with the highest level of privacy and security.`,
    },
    {
      question: "Do you store my credit card information?",
      answer: `We do not store or process your credit card or any other digital financial information, for payments we are integrated with razorpay.`,
    },
  ];

  return (
    <>
      <section id="faq" className="mt-4">
        <h2 style={{ display: "flex", justifyContent: "center" }}>Common Questions</h2>
        <p style={{ display: "flex", justifyContent: "center" }}>
          Got questions? We’re here to assist you!
        </p>
        <CRow className="p-0 mt-4">
          <CCol sm={12} lg={6} className="p-0">
            <h4 style={{ paddingLeft: "20px" }}>General Questions</h4>
            <div className="accordion">
              {generalFaqData.map((faq, index) => (
                <div className="faq-item" key={index}>
                  <button
                    className={`home-accordion-button ${leftIndex === index ? "active" : ""}`}
                    onClick={() => toggleLeftFAQ(index)}
                  >
                    <span className="home-plus">{leftIndex === index ? "-" : "+"}</span>{" "}
                    {faq.question}
                  </button>
                  <div
                    className="home-accordion-content"
                    style={{ display: leftIndex === index ? "block" : "none" }}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </CCol>
          <CCol sm={12} lg={6} className="p-0 mmt-2">
            <h4 style={{ paddingLeft: "20px" }}>Data Safety Questions</h4>
            <div className="accordion">
              {securityFaqData.map((faq, index) => (
                <div className="faq-item" key={index}>
                  <button
                    className={`home-accordion-button ${rightIndex === index ? "active" : ""}`}
                    onClick={() => toggleRightFAQ(index)}
                  >
                    <span className="home-plus">{rightIndex === index ? "-" : "+"}</span>{" "}
                    {faq.question}
                  </button>
                  <div
                    className="home-accordion-content"
                    style={{ display: rightIndex === index ? "block" : "none" }}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </CCol>
        </CRow>
      </section>
    </>
  );
};

export default Faqs;
