async function apiCall(url, options = {}) {
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const token = userdata ? userdata.data.token : null;

  // Set default headers including Authorization
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  // Merge default options with custom options passed to the function
  const config = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options.headers || {}), // Allow overriding headers
    },
  };

  // console.log("url:", url);
  // console.log("config:", config);

  try {
    const response = await fetch(url, config);

    if (response.status === 405) {
      console.log("Token expired, trigger logout");
      // Token expired, trigger logout
      handleLogout();
      return;
    }

    return await response.json();
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}

function handleLogout() {
  localStorage.removeItem("userdata");
  window.location.href = "/";
}

export { apiCall, handleLogout };