import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./assets/scss/style.scss";
import { ToastProvider } from "./layout/ToastContext";
import Home from "./views/landingPage/Home";
import VDPPage from "./views/landingPage/VDPPage";
import DefaultLayout from "./layout/DefaultLayout";
import AppointmentPrint from "./views/pages/components/AppointmentPrint";
import Login from "./views/auth/login/Login";
import Register from "./views/auth/register/Register";
import Forgot from "./views/auth/forgot/Forgot";
import OtpVerify from "./views/auth/otpverify/OtpVerify";
import Reset from "./views/auth/reset/Reset";
import FeedbackPage from "./views/pages/feedback/FeedbackPage";

function App() {
  const location = useLocation();
  const auth = !!localStorage.getItem("userdata") || !!location.state?.data;

  return (
    <div className="App">
      <ToastProvider>
        {auth ? (
          <Routes>
            <Route path="*" element={<DefaultLayout />} />
            <Route path="/print" element={<AppointmentPrint />} />
            <Route path="/feedback/:id" element={<FeedbackPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/otpverify" element={<OtpVerify />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/forgot/reset/:token" element={<Reset />} />
            <Route path="/feedback/:id" element={<FeedbackPage />} />
            {/* <Route path="/*" element={<Navigate to="/login" />} /> */}
            <Route path="/*" element={<Home />} />
            <Route path="/vdp-salonboost" element={<VDPPage />} />
          </Routes>
        )}
      </ToastProvider>
    </div>
  );
}

export default App;
