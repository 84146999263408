import React from 'react'

const Features = () => {
  return (
    <>
      <section id="features" className="features">
        <h2>Features of Salon Management Software</h2>
        <div className="small-feature-tiles">
          <div className="tile">Appointment Scheduling</div>
          <div className="tile">Whatsapp Reminder</div>
          <div className="tile">Whatsapp Bill Dispatch</div>
          <div className="tile">Automated Lead Management</div>
          <div className="tile">Point of Sale</div>
          {/* <div className="tile">Online Booking</div> */}
          <div className="tile">Marketing Campaigns</div>
          {/* <div className="tile">Product Inventory</div> */}
          {/* <div className="tile">Product Consumption</div> */}
          {/* <div className="tile">Gift Vouchers</div> */}
          {/* <div className="tile">Discount Coupon</div> */}
          {/* <div className="tile">Reward Points</div> */}
          {/* <div className="tile">Discount Membership</div> */}
          <div className="tile">Prepaid</div>
          <div className="tile">Packages</div>
          <div className="tile">Automated Feedback</div>
          {/* <div className="tile">Seamless Integrations</div> */}
          <div className="tile">Client Management</div>
          <div className="tile">Staff Management</div>
          <div className="tile">Incentive Management</div>
          <div className="tile">Consent Form</div>
          <div className="tile">Multi-Location Management</div>
          <div className="tile">Advanced Analytics</div>
          <div className="tile">Data Security</div>
        </div>
      </section>
    </>
  );
}

export default Features