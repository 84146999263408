import React, { useEffect } from "react";
import "./landing.css";
import Topbar from "./Topbar";
import Features from "./Features";
import Pricing from "./Pricing";
import Testimonial from "./Testimonial";
import Faqs from "./Faqs";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";
import SecurityFeatures from "./SecurityFeatures";

const Home = () => {
  useEffect(() => {
    sessionStorage.removeItem("hasReloaded");
    const token = localStorage.getItem("userdata");
    if (token) {
      document.getElementById("watiWidget").style.display = "none";
      return
    }
    const script = document.createElement("script");
    script.src = "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?23377";
    script.async = true;

    const options = {
      enabled: true,
      chatButtonSetting: {
        backgroundColor: "#7300e6",
        ctaText: "Chat with us",
        borderRadius: "25",
        marginLeft: "0",
        marginRight: "20",
        marginBottom: "20",
        ctaIconWATI: false,
        position: "right",
      },
      brandSetting: {
        brandName: "Salonboost",
        brandSubTitle: "undefined",
        brandImg: "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
        welcomeText: "Hi there!\nHow can I help you?",
        messageText: "Hello, %0A I have a question about {{page_link}}",
        backgroundColor: "#7300e6",
        ctaText: "Chat with us",
        borderRadius: "25",
        autoShow: false,
        phoneNumber: "917090515255",
      },
    };

    script.onload = () => {
      if (window.CreateWhatsappChatWidget) {
        window.CreateWhatsappChatWidget(options);
      }
    };

    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [window.location.href]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "SalonBoost",
            "url": "https://salonboost.online",
            "sameAs": ["https://facebook.com/salonboost.online", "https://www.instagram.com/salonboost.online/"],
            "logo": "https://salonboost.online/logo.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+917090515255",
              "contactType": "Customer Service"
            }
          }
        `}
        </script>
      </Helmet>
      <Topbar />
      <Features />
      <Pricing />
      <Testimonial />
      <Faqs />
      <SecurityFeatures />
      <Footer />
    </>
  );
};

export default Home;
